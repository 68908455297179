<template>
  <div class="mobile-app">
    <el-container>
      <el-header class="fixed-header">
        <el-button icon="el-icon-arrow-left" @click="goToHome" type="text" class="back-button"></el-button>
        <h1>工单信息</h1>
      </el-header>
      <el-main class="scrollable-main">
        <el-form label-width="100px" size="large" style="width: 90%">
          <el-form-item label="报修人">
            <span>{{getUserName(reports.userId)}}</span>
          </el-form-item>
          <el-form-item label="车间">
            <span>{{getLineName(reports.lineId)}}</span>
          </el-form-item>
          <el-form-item label="设备">
            <span>{{ reports.equipmentName }}</span>
          </el-form-item>
          <el-form-item label="工单类型">
            <span>{{ reports.type }}</span>
          </el-form-item>
          <el-form-item label="故障类型">
            <span>{{ reports.failureType }}</span>
          </el-form-item>
          <el-form-item label="报修时间">
            <span>{{ reports.recordTime }}</span>
          </el-form-item>
          <el-form-item label="故障时间">
            <span>{{ reports.failureTime }}</span>
          </el-form-item>
          <el-form-item label="故障描述">
            <span v-if="reports.description">{{ reports.description }}</span>
            <span v-else>无</span>
          </el-form-item>
          <el-form-item label="故障图">
            <div style="display: flex; flex-wrap: wrap; gap: 10px;">
              <el-image
                  v-for="(file, index) in reportFiles"
                  :key="index"
                  :src="file.fileUrl"
                  :preview-src-list="reportFiles.map(item => item.fileUrl)"
                  style="width: 100px; height: 100px; margin-bottom: 10px;"
              >
              </el-image>
            </div>
          </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <el-form label-width="100px" size="large" style="width: 90%">
          <el-form-item label="工单号">
            <span>{{records.reconum}}</span>
          </el-form-item>
          <el-form-item label="工单类型">
            <span>{{records.type}}</span>
          </el-form-item>
          <el-form-item label="接单时间">
            <span>{{records.startTime}}</span>
          </el-form-item>
          <el-form-item label="开始时间">
            <span>{{records.repairTime}}</span>
          </el-form-item>
          <el-form-item label="结束时间">
            <span>{{records.endTime}}</span>
          </el-form-item>
          <el-form-item label="工时">
            <span>{{records.workHour}}</span>
          </el-form-item>
<!--          <el-form-item label="接单人">-->
<!--            <span>{{users.find(v => v.id === records.userId) ? users.find(v => v.id === records.userId).nickname : ''}}</span>-->
<!--          </el-form-item>-->
          <el-form-item label="维修工">
              <span>{{ getMaintainerNames(records.reconum)  }}</span>
          </el-form-item>
          <el-form-item label="维修说明">
            <span>{{records.shuoming}}</span>
          </el-form-item>
          <el-form-item label="维修分析">
            <span v-if="records.analysis">{{ records.analysis }}</span>
            <span v-else>无</span>
          </el-form-item>
          <el-form-item label="评分" v-if="comments.value">
            <el-rate
                style="margin-top: 8px;"
                v-model="comments.value"
                disabled
                show-score
                text-color="#ff9900"
                score-template="{value}">
            </el-rate>
          </el-form-item>
          <el-form-item label="评价" v-if="comments.comment">
            <span>{{comments.comment}}</span>
          </el-form-item>
          <el-form-item label="到达图" v-if="records.avatarUrl">
            <el-image
                style="width: 100px; height: 100px"
                :src="records.avatarUrl"
                :preview-src-list="[records.avatarUrl]">
            </el-image>
          </el-form-item>
          <el-form-item label="完成图">
            <div style="display: flex; flex-wrap: wrap; gap: 10px;">
              <el-image
                  v-for="(file, index) in recordFiles"
                  :key="index"
                  :src="file.fileUrl"
                  :preview-src-list="recordFiles.map(item => item.fileUrl)"
                  style="width: 100px; height: 100px; margin-bottom: 10px;"
              >
              </el-image>
            </div>
          </el-form-item>

        </el-form>
        <el-row type="flex" justify="center">
          <el-button @click="goToHome" size="large">返回</el-button>
        </el-row>
      </el-main>
      <!-- 底部导航栏 -->
      <el-footer class="fixed-footer">
        <el-row type="flex" justify="center">
          <el-col :span="8" class="footer-item">
            <router-link to="/mobile">首页</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mequipment">设备知识库</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mperson">个人信息</router-link>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
  </div>
</template>

<script>

export default {
  name: "MRecordDetail",
  data() {
    return {
      records: [],
      reports: [],
      comments: [],
      users: [],
      recomaintain: [],
      recordFiles: [],
      lines: [],
      reportFiles: [],
      activeIndex: '1',
      recordId: null,
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      console.log('item id:', this.$route.params.id);
      this.recordId = this.$route.params.id;
      this.request.get("/failureRecord/"+this.recordId).then(res => {
        this.records = res.data;
        this.request.get("/repairReport/"+this.records.reportId).then(res => {
          this.reports = res.data;
        });
        this.request.get("/reportFile/getByReportId/"+this.records.reportId).then(res => {
          this.reportFiles = res.data
        })
      });
      this.request.get("/comment/findRecord/"+this.recordId).then(res => {
        this.comments = res.data;
      });
      this.request.get("/user").then(res => {
        this.users = res.data;
      });
      this.request.get("/recordMaintainer").then(res => {
        this.recomaintain = res.data;
      });
      this.request.get("/line").then(res => {
        this.lines = res.data
      })
      this.request.get("/recordFile/getByRecordId/"+this.recordId).then(res => {
        this.recordFiles = res.data
      })
    },
    // 返回首页
    goToHome() {
      this.$router.push('/mobile');
    },
    getUserName(userId) {
      const user = this.users.find(v => v.id === userId);
      if (!user) return '';
      return user ? user.nickname : '';
    },
    getLineName(lineId) {
      const line = this.lines.find(v => v.id === lineId);
      if (!line) return '';
      return line ? line.name : '';
    },
    getMaintainerNames(reconum) {
      const recomt = this.recomaintain.filter(item => item.reconum === reconum);
      return recomt.map(item => {
        const user = this.users.find(u => u.id === item.userId);

        // Check if workHour is a valid number and not null or undefined
        const workHour = item.workHour != null ? item.workHour.toFixed(2) : '0.00';
        const minutes = Math.floor(workHour * 60);

        const allData = user ? `${user.nickname} (${minutes}分钟)` : '';
        return allData;
      }).join(', ');
    },
  },
};
</script>

<style scoped>
.mobile-app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.fixed-header, .fixed-footer {
  background-color: rgb(38, 52, 69);
  color: #fff;
  text-align: center;
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 1000; /* 确保在顶部 */
}

.fixed-header {
  top: 0;
}

.fixed-footer {
  bottom: 0;
}

.scrollable-main {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  margin-top: 60px; /* 保证 el-main 在 el-header 下面 */
  margin-bottom: 60px; /* 保证 el-main 在 el-footer 上面 */
}

.footer-item {
  line-height: 40px;
  text-align: center;
}

.footer-item a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.footer-item a:hover {
  text-decoration: underline;
}
</style>
